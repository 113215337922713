import { Component, EventEmitter, OnInit, Input, Output, ViewChild, SimpleChanges, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef, ElementRef } from '@angular/core';
import { PlatosService } from 'src/app/services/platos.service';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { LoginService } from 'src/app/services/login.service';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { Restaurante } from 'src/app/models/restaurante';
import { Navegacion } from 'src/app/models/navegacion';
import { GLOBAL } from '../../services/global';
import { Filtro } from 'src/app/models/filtro';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { PublicacionService } from 'src/app/services/publicacion.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';


@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnInit {
  url = "";
  restaurante: Restaurante;
  restaurantes = [];
  verElementos: any = "productos";
  varSearchBar: any = '';
  searchHastang = [];
  isMobile: boolean = false;
  cartaQR: boolean = false;
  varFiltro: Filtro;
  labelFiltro: any;
  tagFiltro: any;
  applyFilters = [];
  nBus: any = 'buscadorHash';
  hashtagsMarca: any = [];
  idHash: String = "";
  value: any = [];
  refModalMensaje: any;
  filterActive: boolean = false;
  verBusquedaFlotante: boolean = true;
  mensajeBusqueda = "";
  styleSpan = {
    color: '#E35100',
  };
  primeraCargaSelect: boolean = false;
  nextPage: boolean = false;
  navegacion: Navegacion;

  // Peticion hecha por categorias components 
  textoPeticionFiltro: string = "";

  divMap: boolean = false;
  divPhoto: boolean = true;
  divCard: boolean = false;
  divFilter: boolean = false;
  divSort: boolean = false;
  opcionElegida: string = "";
  hashtagProducto: any = "";
  hashtagProductoId: any = "";
  scrollPosition: any = 0;
  pos = null;
  pantallaCarga = false;
  activeButton: string = '';
  activeButtonStyle: any = {};

  @ViewChild("containergrid", null) containerGrid: { nativeElement: any; }
  @ViewChild("selectBuscador", null) selectBuscador: { nativeElement: any; }
  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;
  @ViewChild('divVistas', null) divVistas!: ElementRef;
  @ViewChild('inputBuscador', null) inputBuscador!: ElementRef;

  @Output() filtros = new EventEmitter<Filtro>();
  //@Output() entidadSeleccionada = new EventEmitter<string>();
  @Input() filter = '';

  public optionsBuscador: Select2Options;

  constructor(
    private route: ActivatedRoute,
    private rService: RestauranteService,
    private pService: PlatosService,
    private deviceDetector: DeviceDetectorService,
    private puService: PublicacionService,
    private sanitizer: DomSanitizer,
    private lService: LoginService,
    private resolver: ComponentFactoryResolver,
    private router: Router,
    private cfRef: ChangeDetectorRef
  ) {
    this.restaurante = new Restaurante;

    //Filtro
    let filtroStorage = localStorage.getItem("filtro");

    if (filtroStorage == null || filtroStorage == undefined || filtroStorage == "null") {
      this.varFiltro = new Filtro();
    } else {
      this.varFiltro = JSON.parse(filtroStorage);
    }

    //

    //Cuando Selecciona un hashtag

    this.route.params.subscribe(result => {
      this.hashtagProducto = result.hashtag;

      if (this.hashtagProducto != "" && this.hashtagProducto != undefined) {
        this.pantallaCarga = true;
      }

    });

    //

    this.filtroSeleccionado();

    //Navegacion
    let navegacion: any = localStorage.getItem("navegacion");
    if (navegacion == undefined) {
      navegacion = {
        searchBar: "",
        searchHastang: "",
        filtro: null,
        item: this.verElementos,
        footer: "card",
        page: 1
      }

      localStorage.setItem("navegacion", JSON.stringify(navegacion));
    } else {
      navegacion = JSON.parse(navegacion);
      this.varSearchBar = navegacion.searchBar;
      this.searchHastang = navegacion.searchHastang;
      this.hashtagProductoId = navegacion.searchHastang;
      this.verElementos = navegacion.item;
      this.scrollPosition = navegacion.scroll;

      if (this.hashtagProductoId != "") {
        this.pantallaCarga = true;
      }
    }

  }

  ngOnInit() {
    this.url = GLOBAL.url;
    this.isMobile = this.deviceDetector.isMobile();

    this.puService.getAllHashtagMarca().subscribe(result => {
      this.hashtagsMarca = result;
    });

    this.optionsBuscador = {
      multiple: true,
      placeholder: '#hashtag',
      id: this.nBus,
    }

    if (this.pos == null) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
        });
      }
    }

  }

  ngAfterViewInit() {
    if (this.hashtagProducto != undefined) {
      this.puService.getIdHashtagMarca(this.hashtagProducto).subscribe(result => {
        this.idHash = result[0].id;
        const arrayHash = [];
        const valueArray = [];
        //value[0] = this.hashtagProducto;
        valueArray[0] = this.idHash;
        arrayHash['value'] = valueArray;
        //this.value = [idHash];
        this.value = ['' + this.idHash];
        setTimeout(() => {
          this.primeraCargaSelect = true;
          this.pantallaCarga = false;

          this.route.queryParamMap.subscribe((params) => {
            let verMapa = params.get("mapa");
            if (verMapa != null && verMapa != undefined) {
              this.activeButton = 'map';
              this.activeButtonStyle = { 'map': '#E35100' };
              this.showMap();
            }
          });

        }, 2000);

      });
    } else if (this.hashtagProductoId != "") {

      setTimeout(() => {
        this.idHash = this.hashtagProductoId;
        const arrayHash = [];
        const valueArray = [];
        //value[0] = this.hashtagProducto;
        valueArray[0] = this.idHash;
        arrayHash['value'] = valueArray;
        //this.value = [idHash];
        this.value = ['' + this.idHash];
        this.primeraCargaSelect = true;
        this.pantallaCarga = false;

        this.route.queryParamMap.subscribe((params) => {
          let verMapa = params.get("mapa");
          if (verMapa != null && verMapa != undefined) {
            this.activeButton = 'map';
            this.activeButtonStyle = { 'map': '#E35100' };
            this.showMap();
          }
        });
      }, 2000);
    } else {
      this.primeraCargaSelect = true;
    }
  }

  scrollData() {
    if (this.divVistas) {
      this.divVistas.nativeElement.scrollTo(0, this.scrollPosition);
    }
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  filtroSeleccionado() {

    if ((this.varFiltro.distancia == undefined || parseInt(this.varFiltro.distancia) == 0) &&
      this.varFiltro.tipoEstablecimiento == undefined &&
      this.varFiltro.origenDeLosProductos == undefined &&
      this.varFiltro.dieta == undefined) {
      this.filterActive = false;
    } else {
      this.filterActive = true;
    }
  }

  peticionFiltro(peticion: string) {
    this.textoPeticionFiltro = peticion;
  }

  showMap() {
    this.divMap = true;
    this.divCard = false;
    this.divPhoto = false;
    this.divFilter = false;
    this.divSort = false;
    this.filtroSeleccionado();
  }

  showPhotos() {
    this.divMap = false;
    this.divPhoto = true;
    this.divCard = false;
    this.divFilter = false;
    this.divSort = false;
    this.filtroSeleccionado();
  }

  showCard() {
    this.divMap = false;
    this.divPhoto = false;
    this.divCard = true;
    this.divFilter = false;
    this.divSort = false;
    this.filtroSeleccionado();
  }

  showFilter() {
    this.divMap = false;
    this.divPhoto = false;
    this.divCard = false;
    this.divFilter = true;
    this.divSort = false;
    this.filtroSeleccionado();
  }

  showSort() {
    this.divMap = false;
    this.divPhoto = false;
    this.divCard = false;
    this.divFilter = false;
    this.divSort = true;
    this.filtroSeleccionado();
  }

  fFilterSelected(value: any) {
    this.varFiltro = value;
    this.filtroSeleccionado();
  }

  changedSelectBuscador = () => {

    this.actualizarNavegacion();

  }

  changeMensajeBusqueda = (mensaje) => {
    this.mensajeBusqueda = mensaje;
  }

  deleteSearch() {
    this.varSearchBar = '';
    this.actualizarNavegacion();
  }

  changeStatePage(value) {
    this.nextPage = value;
  }

  onScroll(event) {
    this.clickBusquedaFlotante(true);
    this.scrollPosition = event.target.scrollTop; // Obtener la posición de desplazamiento actual
    const scrollHeight = event.target.scrollHeight; // Obtener la altura total del contenedor
    const clientHeight = event.target.clientHeight; // Obtener la altura visible del contenedor

    let navegacion: any = JSON.parse(localStorage.getItem("navegacion"));

    navegacion.scroll = this.scrollPosition;

    localStorage.setItem("navegacion", JSON.stringify(navegacion));

    // Comprobar si está en la parte inferior
    if (this.scrollPosition + clientHeight + 2 >= scrollHeight) {
      this.nextPage = true;
    }
  }

  onChangeView(tipoElementos) {
    this.verElementos = tipoElementos;
    this.actualizarNavegacion();
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  actualizarNavegacion() {
    let navegacion: any = localStorage.getItem("navegacion");

    if (navegacion != null) {
      navegacion = JSON.parse(navegacion);
      navegacion.searchBar = this.varSearchBar;
      navegacion.searchHastang = this.searchHastang;
      navegacion.item = this.verElementos;
      navegacion.page = 1;
      navegacion.scroll = 0;
      localStorage.setItem("navegacion", JSON.stringify(navegacion));
    }
  }

  clickBusquedaFlotante(valor: boolean) {
    this.verBusquedaFlotante = valor;
    if (!valor) {
      setTimeout(() => {
        this.inputBuscador.nativeElement.focus();
      }, 500);
    }
  }

  focoBusqueda() {

  }
}

